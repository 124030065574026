import { isAroundZero } from './helper.js';
var mathSin = Math.sin;
var mathCos = Math.cos;
var PI = Math.PI;
var PI2 = Math.PI * 2;
var degree = 180 / PI;
var SVGPathRebuilder = function () {
  function SVGPathRebuilder() {}
  SVGPathRebuilder.prototype.reset = function (precision) {
    this._start = true;
    this._d = [];
    this._str = '';
    this._p = Math.pow(10, precision || 4);
  };
  SVGPathRebuilder.prototype.moveTo = function (x, y) {
    this._add('M', x, y);
  };
  SVGPathRebuilder.prototype.lineTo = function (x, y) {
    this._add('L', x, y);
  };
  SVGPathRebuilder.prototype.bezierCurveTo = function (x, y, x2, y2, x3, y3) {
    this._add('C', x, y, x2, y2, x3, y3);
  };
  SVGPathRebuilder.prototype.quadraticCurveTo = function (x, y, x2, y2) {
    this._add('Q', x, y, x2, y2);
  };
  SVGPathRebuilder.prototype.arc = function (cx, cy, r, startAngle, endAngle, anticlockwise) {
    this.ellipse(cx, cy, r, r, 0, startAngle, endAngle, anticlockwise);
  };
  SVGPathRebuilder.prototype.ellipse = function (cx, cy, rx, ry, psi, startAngle, endAngle, anticlockwise) {
    var dTheta = endAngle - startAngle;
    var clockwise = !anticlockwise;
    var dThetaPositive = Math.abs(dTheta);
    var isCircle = isAroundZero(dThetaPositive - PI2) || (clockwise ? dTheta >= PI2 : -dTheta >= PI2);
    var unifiedTheta = dTheta > 0 ? dTheta % PI2 : dTheta % PI2 + PI2;
    var large = false;
    if (isCircle) {
      large = true;
    } else if (isAroundZero(dThetaPositive)) {
      large = false;
    } else {
      large = unifiedTheta >= PI === !!clockwise;
    }
    var x0 = cx + rx * mathCos(startAngle);
    var y0 = cy + ry * mathSin(startAngle);
    if (this._start) {
      this._add('M', x0, y0);
    }
    var xRot = Math.round(psi * degree);
    if (isCircle) {
      var p = 1 / this._p;
      var dTheta_1 = (clockwise ? 1 : -1) * (PI2 - p);
      this._add('A', rx, ry, xRot, 1, +clockwise, cx + rx * mathCos(startAngle + dTheta_1), cy + ry * mathSin(startAngle + dTheta_1));
      if (p > 1e-2) {
        this._add('A', rx, ry, xRot, 0, +clockwise, x0, y0);
      }
    } else {
      var x = cx + rx * mathCos(endAngle);
      var y = cy + ry * mathSin(endAngle);
      this._add('A', rx, ry, xRot, +large, +clockwise, x, y);
    }
  };
  SVGPathRebuilder.prototype.rect = function (x, y, w, h) {
    this._add('M', x, y);
    this._add('l', w, 0);
    this._add('l', 0, h);
    this._add('l', -w, 0);
    this._add('Z');
  };
  SVGPathRebuilder.prototype.closePath = function () {
    if (this._d.length > 0) {
      this._add('Z');
    }
  };
  SVGPathRebuilder.prototype._add = function (cmd, a, b, c, d, e, f, g, h) {
    var vals = [];
    var p = this._p;
    for (var i = 1; i < arguments.length; i++) {
      var val = arguments[i];
      if (isNaN(val)) {
        this._invalid = true;
        return;
      }
      vals.push(Math.round(val * p) / p);
    }
    this._d.push(cmd + vals.join(' '));
    this._start = cmd === 'Z';
  };
  SVGPathRebuilder.prototype.generateStr = function () {
    this._str = this._invalid ? '' : this._d.join('');
    this._d = [];
  };
  SVGPathRebuilder.prototype.getStr = function () {
    return this._str;
  };
  return SVGPathRebuilder;
}();
export default SVGPathRebuilder;